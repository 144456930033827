import { bool, number, object, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { WithLazy, LazyCollection, LazyLoadImages } from "@quintype/components";
import { getCollectionTemplate } from "../../get-collection-template";
import { Suurtaulu } from "../../atoms/suurtaulu";
import "./elections.m.css";
import { Vaalitulokset } from "../../atoms/vaalitulokset";
//import { Vaalitulokset2Vaali } from "../../atoms/vaalitulokset2vaali";
import { VaalituloksetAv } from "../../atoms/vaalitulokset-av";

const ElectionResults = ({ data, reloadTimeInMinutes = 15, shouldReload = false }) => {

  const [pageSlugParsed, setPageSlugParsed] = useState();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      const pageSlug = decodeURI.split('/')[3];
      const pageSlugP = pageSlug.split(/[#?]/)[0];
      setPageSlugParsed(pageSlugP);
    };
  }, [])
  useEffect(() => {
    if (!shouldReload) return;
    let timeElapsed = 0;
    let timerId;

    const Main = () => {
      if (document.hidden && timeElapsed === 0) {
        startTimer();
      }
      if (!document.hidden && timeElapsed > 0 && timeElapsed < reloadTimeInMinutes) {
        clearInterval(timerId);
        timeElapsed = 0;
      }
      if (!document.hidden && timeElapsed >= reloadTimeInMinutes) {
        window.location.reload();
      }
    };

    function updateTimeElapsed() {
      timeElapsed = timeElapsed + 1;
    }
    function startTimer() {
      timerId = window.setInterval(updateTimeElapsed, 60000);
    }
    document.addEventListener("visibilitychange", Main);
    return () => {
      document.removeEventListener("visibilitychange", Main);
    };
  }, []);


  return (
    <React.Fragment>
      <div styleName="elections-top-container">
        <div styleName="container">
          <div styleName="main">
            <div styleName="ad-container">
              {/* PARAATI mobiili*/}
              <div styleName="mob-ad">
                <div id="sas_63333" data-placement-id="paraatipaikka-mobile"></div>
              </div>
              {/* PARAATI deskari ja tabletti*/}
              <div styleName="dsktop-ad">
                <div id="sas_63219" data-placement-id="paraatipaikka-desktop"></div>
              </div>
            </div>
            <div styleName="ad-container">
              {/* PARAATI 2 mobiili*/}
              <div styleName="mob-ad">
                <div id="sas_112313" data-placement-id="paraatipaikka-2-mobile"></div>
              </div>
              {/* PARAATI 2 deskari ja tabletti*/}
              <div styleName="dsktop-ad">
                <div id="sas_112312" data-placement-id="paraatipaikka-2-desktop"></div>
              </div>
            </div>
          </div>
          <div styleName="lazy-pop">
            <div styleName="lazy-load">

              {/*TODO: comment out text below when Vaalitulokset component is set visible*/}
              <p styleName="election-text">Alue- ja kuntavaalien tulokset julkaistaan vaalipäivänä 13.4.2025 klo 20.00 alkaen.</p>


              {/*TODO: set visible when data is cleared*/}

              {/*(pageSlugParsed === "vaalitulokset") && (
                <Vaalitulokset />
              )*/}
              {/*(pageSlugParsed === "vaalitulokset-av") && (
                <VaalituloksetAv />
              )*/}
              {/*pageSlugParsed == "vaalitulokset-2kierros" && (
                <>
                  <Vaalitulokset2Vaali />
                  <a styleName="election-link" href="/vaalitulokset"><div styleName="election-button">Katso ensimmäisen kierroksen tulokset <i className="ri-arrow-right-s-line"></i></div></a>
                </>
                )*/}

              <LazyLoadImages>
                <LazyCollection collection={data.collection} collectionTemplates={getCollectionTemplate} lazyAfter={22} />
              </LazyLoadImages>
            </div>
          </div>
        </div>
        <Suurtaulu />
      </div>
    </React.Fragment>
  );
};

ElectionResults.propTypes = {
  data: shape({
    collection: object
  }),
  reloadTimeInMinutes: number,
  shouldReload: bool
};

function mapStateToProps(state) {
  return {
    shouldReload: get(state, ["qt", "config", "publisher-attributes", "shouldReload"], false),
    reloadTimeInMinutes: Number(get(state, ["qt", "config", "publisher-attributes", "reloadTimeInMinutes"], 15))
  };
}

export const ElectionResultsPage = connect(mapStateToProps, () => ({}))(ElectionResults);
